<template>
  <el-form
    id="change"
    :rules="ruleFormRules"
    :model="ruleForm"
    status-icon
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
  >
    <el-form-item label="旧密码" prop="pass">
      <el-input
        type="password"
        v-model="ruleForm.pass"
        autocomplete="on"
        placeholder="请输入原始登录密码"
        show-password
      >
      </el-input>
    </el-form-item>

    <el-form-item label="新密码" prop="checkPass">
      <el-input
        type="password"
        v-model="ruleForm.checkPass"
        autocomplete="on"
        placeholder="请输入新密码"
        show-password
      ></el-input>
    </el-form-item>
    <el-form-item label="确认密码" prop="acheckPass">
      <el-input
        type="password"
        v-model="ruleForm.acheckPass"
        placeholder="请再次输入新密码"
        show-password
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-button @click="updatepwFunc(ruleForm)" type="primary"
        >修改密码</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.acheckPass !== "") {
          this.$refs.ruleForm.validateField("acheckPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value == this.ruleForm.checkPass) {
        callback();
      } else {
        // console.log(value);
        // console.log(this.ruleForm.checkPass);
        callback(new Error("两次输入密码不一致!"));
      }
    };

    return {
      ruleForm: {
        pass: "",
        checkPass: "",
        acheckPass: "",
      },
      ruleFormRules: {
        pass: [
          { required: true, message: "密码不可为空", trigger: "blur" },
          { validator: validatePass, trigger: "blur", required: true },
        ],
        checkPass: [
          { required: true, message: "密码不可为空", trigger: "blur" },
          { validator: validatePass, trigger: "blur", required: true },
        ],
        acheckPass: [
          { required: true, message: "密码不可为空", trigger: "blur" },
          { validator: validatePass2, trigger: "blur", required: true },
        ],
      },
    };
  },
  methods: {
    updatepwFunc(ruleForm) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$api.post(
            "/user/editPwd",
            null,
            {
              userPass: this.ruleForm.pass,
              newUserPass: this.ruleForm.acheckPass,
            },
              (successRes) => {
                console.log(successRes);
                if (successRes.status == 200) {
                  this.$message('操作成功');
                  this.$router.push({name: "deskList"});
                } else {
                  this.$message(successRes.message);
                }
              },
              (failureRes) => {
                this.$message('操作失败');
              }
          );
        }
      });
    },
  },
};
</script>